<template>

  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">环保问题管理</a></li>
            <li class="breadcrumb-item active" id="title3">环保问题详情</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">环保问题详情</h4>
      </div>
    </div>
     <el-form :model="formData" label-width="20%" align="left">
<el-form-item label="问题ID">
{{formData.eid}}</el-form-item>
<el-form-item label="问题标题">
{{formData.etitle}}</el-form-item>
<el-form-item label="问题图片" prop="eimage">
<img :src="'https://www.jiuyihuishou.online/useclothingrecycling/' +formData.eimage" style="width: 150px;height: 150px" />
</el-form-item>
<el-form-item label="问题内容" prop="econtent">
<div v-html="formData.econtent"></div>
</el-form-item>
<el-form-item label="发布时间">
{{formData.etime}}</el-form-item>
<el-form-item>
<el-button type="info" size="small" @click="back" icon="el-icon-back">返 回</el-button>
</el-form-item>
</el-form>


  </div>

</template>
<script>
        
        import request, { base } from "../../../utils/http";
        export default {
            name: 'EnvironmentDetail',
            components: {
            },
            data() {
                return {
                    id: '',
                    formData: {}, //表单数据         
        
                };
            },
            created() {
                this.id = this.$route.query.id; //获取参数
                this.getDatas();
            },
        
        
            methods: {
        
                //获取列表数据
                getDatas() {
                    let para = {
                    };
                    this.listLoading = true;
                    let url = base + "/environment/get?id=" + this.id;
                    request.post(url, para).then((res) => {
                        this.formData = JSON.parse(JSON.stringify(res.resdata));
                        this.listLoading = false;
                    });
                },
        
                // 返回
                back() {
                    //返回上一页
                    this.$router.go(-1);
                },
        
            },
        }

</script>
<style scoped>
</style>
 

